5<template>
	<div class="backcolor-f">
		<div style="position: absolute;z-index: 9;top:10px;right: 10px;">
			<el-button type="primary" class="cursor" @click="importfile" size="mini"  >
				导入客户</el-button>
			<el-button class="cursor" size="mini" style="margin-right: 10px;" @click="assignCustomers">批量分配</el-button>
		</div>
		<el-tabs v-model="activeName" type="card" @tab-click="changeTable">
			<el-tab-pane label="待分配客户" name="1">
				<div style="width: 100%;padding: 0px 20px;margin: 5px 0 ;">
					<el-table :data="CustomerList" v-loading="loading" tooltip-effect="dark" border stripe
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="客户全名" prop="UserName" width="140" height="40">
						</el-table-column>
						<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="最近动态时间" prop="RecentNewsTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.RecentNewsTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="最后跟进人" prop="FollowUserName" width="100px"> </el-table-column>
						<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.AddTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-dropdown @command="handleCommand">
									<span class="el-dropdown-link cursor">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{val:scope.row}">分配客户</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-tab-pane>
			<el-tab-pane label="公司客户" name="2">
				<div style="width: 100%;padding: 0px 20px;margin: 5px 0 ;">
					<el-table :data="CustomerList" v-loading="loading" tooltip-effect="dark" border stripe
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="客户全名" prop="UserName" width="140" height="40">
						</el-table-column>
						<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="最近动态时间" prop="RecentNewsTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.RecentNewsTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="最后跟进人" prop="FollowUserName" width="100px"> </el-table-column>
						<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.AddTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-dropdown @command="handleCommand">
									<span class="el-dropdown-link cursor">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{val:scope.row}">分配客户</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-tab-pane>
		</el-tabs>
		<el-pagination background layout="total,prev, pager, next,sizes,jumper" highlight-current-row :total="total"
			:page-size="listQuery.pageSize" :page-sizes="[15,20,50,100,200,500]" @current-change="pageIndexChange"
			@size-change="pageSizeChange" style="text-align: center;" />
		<div class="margin-top padding-lr-lg">


		</div>
		<el-dialog title="公司客户导入" :visible.sync="showimport" @close="getCustomerList" width="550px">
			<el-upload class="upload-demo" style="padding:40px 0px;text-align:center" drag
			:action="'/antis-service/api/Customer/Import?type='+listQuery.CustomerType"
			:headers="{Authorization:token}"
			:on-success="up_success"> 
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				<div class="el-upload__tip" slot="tip">只能上传excel文件,单次最多上传1000个信息 点击 <a style="color:#2FA4E7"
					target="_blank" :href="baseURL+'/temp/客户导入模板.xlsx'">下载导入模板</a></div>
			</el-upload>
		</el-dialog>
	</div>
</template>

<script>
		import {
		getToken
	} from '@/utils/auth'
	import baseURL from '@/utils/config'
	import AsList from '@/components/as-list'
	export default {
		name: 'management',
		components: {
			AsList,

		},
		data() {
			return {
				token:getToken(),
				baseURL,
				loading: true,
				showimport:false,
				total: 0,
				distributionCustomerids:'',
				activeName: '1',
				CustomerList: [],
				listQuery: {
					IsNotAllocated:true,
					CustomerType: 4,
					pageIndex: 1,
					pageSize: 15,
					Sort: [{
						Field: "id",
						Type: 1
					}]
				},
			}
		},
		created() {
			this.getCustomerList()
		},
		methods: {
			up_success(data) { 
				if (data.success) {
					this.$alert('导入完成 '+data.message, '导入提示', {
						confirmButtonText: '确定',
						callback: action => {
							 
						}
					});
				}
				else {
					this.$message.error("导入失败")

				}
			},
			importfile() {  
				this.showimport = true;
			},
			goNav(url) {
				this.$router.push(url)
			},
			pageSizeChange(val) {
				this.listQuery.PageIndex = 1;
				this.listQuery.pageSize = val;
				this.getCustomerList();
			},
			pageIndexChange(val) {
				this.listQuery.PageIndex = val;
				this.getCustomerList();
			},
			changeTable(tab, event){
				if(tab.name==1){
					this.listQuery.CustomerType=4
				}else{
					this.listQuery.CustomerType=5
				}
				this.getCustomerList()
			},
			getCustomerList() {
				this.API.selectCustomerList(this.listQuery).then(res => {
					this.loading = false
					this.CustomerList = res.data.rows
					this.total = res.data.total
				})
			},
			
			handleSelectionChange(val) {
				let ids=[]
				val.forEach(item=>{
					ids.push(item.id)
				})
				this.distributionCustomerids=ids.join(',')
				console.log(this.distributionCustomerids);
			},
			handleCommand(command) {
				this.$Dialog.ChooseStaff({Customerids:command.val.id}).then(res=>{
					this.getCustomerList()
				})
			},
			//批量分配
			assignCustomers(){
				if(this.distributionCustomerids.length!=0){
					this.$Dialog.ChooseStaff({Customerids:this.distributionCustomerids}).then(res=>{
						this.getCustomerList()
					})
					console.log(this.distributionCustomerids)
				}else{
					this.$message("请先选择需要被分配的客户")
				}
			}
		}
	}
</script>

<style scoped>
	>>>.el-table {
		box-shadow: 0 0 8px #cdcdcd;
	}

	>>>.el-table th {
		background-color: #2FA4E7;
		color: #fff;
	}

	>>>.el-tabs__item:hover {
		color: #2FA4E7;
	}

	.myClient-right-5 {
		color: #2FA4E7;
		font-weight: bold;
		font-size: 16px;
		margin-right: 10px;
	}

	>>>.is-active {
		height: 43px;
		font-size: 17px;
		font-weight: bold !important;
	}
</style>
